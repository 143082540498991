.faq {
    margin: 23vh auto 10vh;
    max-width: 1200px;
}

.h1-title {
    text-align: center;
    font-size: 48px;
    line-height: 60px;
    color: #010f2e;
    font-weight: bold;
    font-family: "Jost", sans-serif;
    margin-bottom: 18px;
    max-width: 640px;
}


.faq-arrow{
    position          : relative;
    font-size         : 18px;
    cursor            : pointer;
    line-height       : 1.2em;
    color             : #222;
    font-weight       : 400;
    padding           : 28.5px 30px;
    padding-left      : 58px;
    transition        : all 500ms ease;
    -ms-transition    : all 500ms ease;
    -o-transition     : all 500ms ease;
    -moz-transition   : all 500ms ease;
    -webkit-transition: all 500ms ease;
  }

.faq-bottom-line{
    margin: 3rem 0 5rem;
}

.banner-content {
    padding: 50px 0 15px;
}

