.main-experience {
  position: relative;
  padding: 60px 0 46px 0;
  overflow: visible;
}
.provider-experience{
  margin-bottom: 70px;
}

@media screen and (min-width: 767px) {
  .main-experience {
    padding: 80px 0 66px 0;
  }
}
@media screen and (min-width: 991px) {
  .main-experience {
    padding: 120px 0 113px 0;
  }
}

.experience-skills {
  margin-top: 35px;
}
.experience-skill-bar-box {
  position: relative;
  margin-bottom: 20px;
}
.h3-title.experience-skill-bar-title {
  margin-bottom: 9px;
}

.experience-skill-list ul {
  margin-right: 100px;
  min-width: 100%;
  width: 100%;
}

.experience-skill-list ul li{
  position: relative;
  font-size: 13px;
  color: #777777;
  font-weight: bold;
  white-space: normal;
  line-height: 20px;
  margin-left: -20px;
}


.experience-skill-list ul li span{
  display: inline-flex;
  margin-left: -.25em;
  line-height: 16px;

}



.experience-skill-bar:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 100%;
  height: 8px;
  background: #ebebeb;
  border-radius: 5px;
}
.experience-skill-bar-inner {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 0;
  height: 22px;
  border-radius: 5px;
  overflow: visible !important;
}
.experience-skill-bar-inner:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 100%;
  height: 8px;
  background: #fc9d44;
  box-shadow: -6px 6px 30px rgb(252 157 68 / 40%);
  border-radius: 5px;
}
@media screen and (min-width: 991px) {
  .experience-skill-bar-box {
    position: relative;
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 768px) {
  .experience-skill-list p li{
   flex-wrap: wrap;

  }
}

.experience-img {
  position: relative;
}
.experience-img img {
  vertical-align: middle;
  max-width: 100%;
  margin-left: 0px;
}
