#main-faq {
  background-color: #777777;
  padding: 40px 0;
}

#main-faq .sub-faq{
  width: 900px;
  padding: 0 80px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 350px .9fr;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  height: 390px;
}

#main-faq .sub-faq .faq-content-left, #main-faq .sub-faq .faq-content-right {
  height: fit-content;
}

.faq-content-left .sub-title {
  color: white;
  margin-bottom: 17px;
}
.faq-content-left .h4-title {
  color: white;
  margin-bottom: 20px;
}

.faq-content-left p {
  margin-bottom: 0px;
  font-size: 20px;
  color: #24252aa3;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  vertical-align: center;
}
#main-faq .sub-faq .faq-content-right{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: .5rem;
}
#main-faq .sub-faq .faq-content-right .box {
  height: 70px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 0px 20px;
  transition: all .3s ease;
}

#main-faq .sub-faq .faq-content-right .box .title-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

#main-faq .sub-faq .faq-content-right .box .title-box h1{
  font-size: 17px;
  vertical-align: center;
  font-family: "Jost", sans-serif;

}

#main-faq .sub-faq .faq-content-right .box  p {
  font-size: 14px;
  text-align: match-parent;
  color: whitesmoke;
  font-family: arial, Verdana;
  margin-top: 15px;
}

#main-faq .icon,  #main-faq .sub-faq .faq-content-right .box:hover .title-box h1{
  color: black;
}

#main-faq .sub-faq .faq-content-right .box:hover{
  height: fit-content;
  background-color: #9b111e;
}

@media (max-width: 244px) {
  #main-faq .sub-faq .faq-content-right .box:nth-child(2) {
    padding: 15px 20px;
  }
}

@media (min-width: 245px) and (max-width: 308px) {
  #main-faq .sub-faq .faq-content-right .box {
    padding: 15px 20px;
  }
  #main-faq .sub-faq .faq-content-right .box:nth-child(2) {
    padding: 26px 20px;
  }
}

@media (max-width: 367px) {
  #main-faq .sub-faq {
    padding: 0 10px;
  }
}

@media (min-width: 368px) and (max-width: 468px) {
  #main-faq .sub-faq {
    padding: 0 30px;
  }
}

@media (max-width: 696px) {
  #main-faq .sub-faq {
    grid-template-columns: 1fr;
    height: fit-content;
  }
}

@media (min-width: 697px) and (max-width: 833px) {
  #main-faq .sub-faq {
    padding: 0 20px;
  }
}

@media (max-width: 1023px) {
  #main-faq .sub-faq {
    width: 100%;
  }
}

@media screen and (min-width: 991px) {
}
@media screen and (min-width: 1199px) {
}
@media screen and (min-width: 1279px) {
}
