.main-about-us {
  position: relative;
  padding: 0 0 60px 0;
}
.about-img-box img {
  width: 100%;
}
.about-text {
  margin-bottom: 36px;
}

@media screen and (min-width: 767px) {
  .main-about-us {
    position: relative;
    padding: 0 0 80px 0;
  }
}
@media screen and (min-width: 991px) {
  .main-about-us {
    position: relative;
    padding: 80px 0 120px 0;
  }
  .about-img-box img {
    width: 100%;
    position: relative;
    right: 100px;
    height: 55%;
  }
}
@media screen and (min-width: 1199px) {
}
@media screen and (min-width: 1279px) {
}
