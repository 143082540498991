.sidebar-page-container {
    position: relative;
    padding : 120px 0 90px;
    border-color: #2c3034;
    border-width: 1px;
}

.auto-container {
    padding  : 0px 15px;
    margin   : 0 auto;
}

.categories-widget-two {
    margin-bottom: 30px;
    -webkit-box-shadow: 0px 8px 16px 0px rgb(155, 152, 152);
    box-shadow        : 0px 8px 16px 0px rgb(155, 152, 152);


    vertical-align: middle;
    background: linear-gradient(
            to top,
            white 0%,
            white 83%,
            rgb(128, 24, 24) 50%,
            rgb(128, 24, 24)100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
}

.heading-8{
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 40px;
    color: white;
}

.categories-widget-two ul {
    position: relative;
    list-style: none;
    padding: 0px;
    margin: 2em 0;
}


.categories-widget-two li {
    font-family: "Jost", sans-serif;
    color: #010f2e;
    font-size: 20px;
    letter-spacing: .75px;
}

.categories-widget-two li icon{
    height: 17px;
    padding: 0 10px 0 0;
    verticalAlign: 0.2;
}



/* Services Details */

.services-details {
    position: relative;
}

.services-details .image-box img {
    margin-bottom: 40px;
    height:400px;
    margin-top:-40px;
}

.services-details .content {
    position     : relative;
    margin-bottom: 50px;
}

.services-details .content .sec-title h2 {
    font-size     : 60px;
    letter-spacing: -3px;
}

.services-details blockquote {
    position          : relative;
    -webkit-box-shadow: 0px 8px 16px 0px rgba(40, 40, 40, 0.14);
    box-shadow        : 0px 8px 16px 0px rgba(40, 40, 40, 0.14);
    padding           : 30px 40px;
    margin            : 40px 0;
}

.services-details blockquote:before {
    position: absolute;
    content : '';
    left    : 0;
    top     : 0;
    width   : 6px;
    height  : 100%;
}

.services-details blockquote h4 {
    position    : relative;
    font-size   : 22px;
    font-weight : 700;
    padding-left: 45px;
}

.services-details blockquote h4:before {
    position: absolute;
    content : '';
    left    : 0;
    top     : 7px;
    width   : 35px;
    height  : 2px;
}

@media screen and (max-width: 415px) {
    .services-details .image-box img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 105%;

    }

    .heading-8{
        text-align: center;
        font-family: 'Open Sans', sans-serif;
        font-size: 30px;
        color: white;
    }

    .categories-widget-two ul {
        position: relative;
        list-style: none;
        padding: 0px;
        margin-top: 43px;
    }



}