.history-section {
    position        : relative;
    background-color: #9b111e;
}

.history-section .tab-content .sec-title h2 {
    font-size     : 48px;
    letter-spacing: 0px;
}

.history-section .tab-content .sec-title {
    margin-bottom: 10px;

}

.history-section .tab-content .text {
    color        : #fff;
    margin-bottom: 35px;
    margin-right: 30px;
}

.history-section .tab-btn-style-one {
    -webkit-box-pack: justify;
    -ms-flex-pack   : justify;
    justify-content : space-between;
    border-bottom   : 0;
    margin-bottom   : 60px;
    grid-gap: 1.2em;
}


.history-section .tab-btn-style-one span{
    display:inline-grid;
    position: relative;
    padding: 0 10px;

}

.history-section .tab-btn-style-one .nav-item .nav-link.active {
    color                  : #fff;
    -webkit-text-fill-color: inherit;
    background-clip        : inherit;
    border: 2px solid white;
    position: relative;
}

.history-section .sec-title .title {
    -webkit-text-fill-color: inherit;
    margin-bottom: -6px;
    font-size: 80px;
    color: antiquewhite;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    position: relative;
    font-family: "Jost", sans-serif;



}

.history-section .tab-btn-style-one .nav-item .nav-link {
    border-color           : transparent;
    background: #282828;
}

.history-section .tab-btn-style-one .nav-item .nav-link.active:before {
    display: none;
}

.history-section .tab-btn-style-one .nav-item .nav-link {
    color        : #fff;
    padding      : 15.5px 45px;
    border-radius: 30px;
    font-size    : 16px;
    background   : #282828;

}

.history-section .image img {
    position: relative;
    margin-top: -20px;
    margin-bottom: 20px;
    margin-left: 0px;
    width: 500px;
    max-width: 100%;
    height: auto;
}

.history-section .content {
    margin-bottom: 30px;
}

.fadeInUp-animated{
    animation: slide-in 1s;
}

@keyframes slide-in{
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}

