.cards{
    position     : relative;
    margin-bottom: 30px;
    display: inline-block;
    width: 600px;
    min-width: 600px;
    max-width: 100%;


}
.faq__question-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 600px;
    max-width: 100%;
    position: relative;
    padding-top       : 0px;
    margin-bottom     : 25px;
    -webkit-box-shadow: 0px 5px 21.25px 3.75px rgba(217, 217, 217, 0.65);
    box-shadow        : 0px 5px 21.25px 3.75px rgba(217, 217, 217, 0.65);
}

.faq__question{
     margin: 1rem;
     margin-right: 0;
     font-size: 1.3rem;
     text-align: left;
     flex-basis: 90%;
 }

.faq__answer-container{

    background: rgba(156, 17, 31, 100);
    opacity: 0.6;
     padding: 5%;
     width: 100%;
     border-radius: 0 0 10px 10px;
     text-align: left;
     color: whitesmoke;

 }


.faq__answer{
    position          : relative;
    font-size         : 18px;
    cursor            : pointer;
    line-height       : 1.2em;
    color             : black;
    font-weight       : 700;
    padding           : 28.5px 30px;
    padding-left      : 58px;
    transition        : all 500ms ease;
    -ms-transition    : all 500ms ease;
    -o-transition     : all 500ms ease;
    -moz-transition   : all 500ms ease;
    -webkit-transition: all 500ms ease;
 }
.arrows{
    margin-right: 50px;
}

#faq-button{
    display: block;
    text-decoration: none;
    border-top: solid 1px #2c3034;
    border-bottom: solid 1px #2c3034;
    padding: 0 1rem;
    margin: 1rem auto;
    width: fit-content;
}

#faq-button:link{
    color: #2c3034;
}
#faq-button:visited{
    color:yellow;
}

#faq-button:hover{
    transform: scale(1.1);
    box-shadow: 0 0 4px hsla(0,0%, 0%, .3);
}

@media screen and (max-width:767px) {
    .faq__question-container{
        align-items: center;
        max-width: 50%;
        margin-right: auto;
        flex-shrink: 50;
        padding-right: 20px;
    }

    .faq__question{
        margin-right: 8px;
        font-size: 1.3rem;
        text-align: center;
    }

    .faq__answer{
        font-size: 1.3rem;
        text-align: justify-all;
    }
    .faq__answer-container{
        max-width: 50%;
        padding: 0;
        align-items:center;

    }




}

