.main-our-services {
  padding-top: 51px;
}
.our-services-title {
  text-align: center;
}
.sub-serv-title {
  display: block;
  font-size: 18px;
  line-height: 28px;
  color: #9b111e;
  font-weight: bold;
  font-family: "Jost", sans-serif;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 10px;
}
.h2-serv-title {
  font-size: 65px;
  line-height: 75px;
  font-size: 35px;
  line-height: 45px;
  font-weight: bold;
  color: #010f2e;
  font-family: "Jost", sans-serif;
  margin-bottom: 30px;
}
.our-service-box {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 35px 25px;
  text-align: center;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 1px 1px 60px rgb(0 0 0 / 8%);
  overflow: hidden;
  margin-bottom: 30px;
}
.our-service-text {
  position: relative;
}
.our-service-text-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-bottom: 25px;
  transition: 0.3s;
}

.svg-inline--fa {
  height: 3em;
  overflow: visible;
  vertical-align: -0.125em;
}

.our-service-text-icon img {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border: 2px solid black;
  border-radius: 4px;
  margin-bottom: 25px;
  transition: 0.3s;

}
.our-serv-icon-h3 {
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 10px;
  color: #010f2e;
  font-weight: bold;
  font-family: "Jost", sans-serif;
  transition: color 0.1s ease-in-out;
}
.our-serv-icon-p {
  font-size: 15px;
  line-height: 24px;
  color: #777777;
  font-weight: normal;
  margin-bottom: 12px;
  transition: color 0.1s ease-in-out;
  height: 100%;
  max-height: 100%;
}
.our-service-box:hover .our-service-text-icon {
  animation-name: icon-scale;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
.our-service-box:hover .our-serv-icon-h3 {
  color: #010f2e;
}
.our-service-box:hover .our-serv-icon-p {
  color: #777777;
}
.serv-btn{
  height: 30px;
  display: inline-block;
  font-size: 13px;
  text-decoration: none;
  font-weight: 500;
  text-transform: uppercase;
  padding: 5px ;
  width: 87%;
  background-color: #9b111e;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  transition: 0.3s;
  margin-left: 50%;
  text-align: center;
  transform: translateX(-50%);


}

.serv-btn:hover {
  background-position: right center;
  color: #ffffff;
  text-decoration: none;
}

@media screen and (min-width: 991px) {
  .serv-btn {
    margin-left: 0;
    transform: translateX(0);
  }
}


@keyframes icon-scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.our-service-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  width: 100%;
  height: 0;
  border-radius: 4px;
  transition: 0.5s;
  z-index: 0;
}

.our-service-box:hover::before {
  height: 0;
  bottom: 0;
  top: auto;
  height: 100%;
}

@media screen and (min-width: 575px) {
  .main-our-services {
    padding-top: 71px;
  }
  .h2-serv-title {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 767px) {
  .our-serv-icon-h3 {
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 12px;
  }
  .main-our-services {
    padding-top: 71px;
  }
  .sub-serv-title {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .h2-serv-title {
    font-size: 48px;
    line-height: 58px;
  }
}
@media screen and (min-width: 991px) {
  .main-our-services {
    padding-top: 111px;
  }
  .h2-serv-title {
    font-size: 52px;
    line-height: 62px;
  }
}
@media screen and (min-width: 1199px) {
  .h2-serv-title {
    font-size: 65px;
    line-height: 75px;
  }
}


@media screen and (min-width: 1400px) {
  .our-serv-icon-p {
    text-align: justify-all;
    width: 280px;
    max-height: 100%;
    margin-bottom: 20px;
    padding-bottom: 10px;
    margin-right: 0;
    margin-left: -12px;
  }
  .serv-btn{
    transform: translateY(-40%);
  }

  .our-service-box {
    margin-bottom: -20px;
    padding-top: 10px;
    max-height: 95%;
    margin-left: 0;
    margin-right: 0;
  }
  .our-serv-icon-h3 {
    margin-bottom: 5px;
    margin-top: -20px;

  }
  .our-service-text-icon img{
    margin-bottom: 5px;
  }

}