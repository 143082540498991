.news-section {
    position: relative;
    padding : 120px 0 70px;
}

.news-section .sec-bg {
    position       : absolute;
    left           : 0;
    top            : 0;
    width          : 100%;
    height         : 605px;
    background-size: cover;
}

.news-section .sec-bg:before {
    position        : absolute;
    content         : '';
    background-color: #282828;
    left            : 0;
    top             : 0;
    width           : 100%;
    height          : 100%;
    opacity         : .9;
}

.news-block-one {
    position: relative;
}

.news-block-one .inner-box {
    position     : relative;
    margin-bottom: 30px;
}

.news-block-one .image {
    position: relative;
    overflow: hidden;
}

.news-block-one .image img {
    width             : 100%;
    -webkit-transition: 1s;
    -o-transition     : 1s;
    transition        : 1s;
    max-height: 1.0in;
    min-height: 2.6in;
    max-width: 100%;
    display: block;
}

.news-block-one .inner-box:hover .image img {
    -webkit-transform: scale(1.2);
    -ms-transform    : scale(1.2);
    transform        : scale(1.2);
}

.news-block-one .lower-content {
    position          : relative;
    padding           : 0 20px 20px;
    -webkit-box-shadow: 0px 8px 16px 0px rgba(40, 40, 40, 0.1);
    box-shadow        : 0px 8px 16px 0px rgba(40, 40, 40, 0.1);
    background-color  : #fff;
    max-height: 200px;
    min-height: 200px;
}


.news-block-one .category {
    position         : relative;
    font-size        : 14px;
    font-weight      : 700;
    padding          : 6.5px 20px;
    color            : #fff;
    border-radius    : 20px;
    margin: 0 0 0 auto;
    -webkit-transform: translateY(-50%);
    -ms-transform    : translateY(-50%);
    transform        : translateY(-50%);
}

.news-block-one .post-meta {
    position         : relative;
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -ms-flex-wrap    : wrap;
    flex-wrap        : wrap;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    margin-bottom    : 10px;
}

.news-block-one .post-meta li a {
    font-size  : 14px;
    font-weight: 700;
    color      : #282828;
}

.news-block-one .post-meta li {

    text-align: center;
    margin-left: 75px;
    list-style: none;
}

.news-block-one .post-meta li a icon {
    position    : relative;
    margin-right: 5px;
}

.news-block-one h3 {
    font-size    : 22px;
    font-weight  : 700;
    margin-bottom: 15px;
    text-align: center;
}

.news-block-one h3 a {
    color             : #282828;
    -webkit-transition: .5s;
    -o-transition     : .5s;
    transition        : .5s;
    text-align: center;
}


.btn-blog{
    text-align: center;
    margin-top: 10px;
    background-color: #dadada;
    border: #2c3034 3px;
    text-decoration: none;
    height: 60px;
    display: inline-block;
    font-size: 15px;
    line-height: 30px;
    color: black;
    font-weight: 500;
    text-transform: uppercase;
    padding: 15px 32px;
    background-size: 400% 100%;
    background-position: left center;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    outline: none;
    transition: 0.3s;
    margin-left: 50%;
    transform: translateX(-50%);

}

.btn-blog:hover {
    background-position: right center;
    color: black;
}

