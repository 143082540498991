.main-testimonial {
  position: relative;
  padding: 90px 0;
}
@media screen and (max-width: 991px) {
  .main-testimonial {
    padding: 73px 0;
  }
}
@media screen and (max-width: 767px) {
  .main-testimonial {
    padding: 53px 0;
  }
}

.main-testimonial-slider {
  position: relative;
  margin-bottom: 40px;
}

@media screen and (max-width: 991px) {
  .main-testimonial-slider {
    margin-top: 40px;
  }
}

.testimonial-slider-box {
  width: 100%;
  height: auto;
  background: #ffffff;
  border-radius: 4px;
  border: solid 2px transparent;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at bottom, #ab0016, rgba(203, 48, 48, 0.81));
  background-origin: border-box;
  background-clip: content-box, border-box;
  position: relative;
  box-shadow: 2px 2px 60px rgba(115, 57, 57, 0.78);
  z-index: 1;
}
.main-testimonial-slider::before,
.main-testimonial-slider::after {
  content: "";
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  width: calc(100% - 44px);
  height: 100%;
  border-radius: 4px;
  border: solid 2px #ab0016;
  opacity: 0.4;
  z-index: 0;
}
.main-testimonial-slider::after {
  top: 40px;
  width: calc(100% - 84px);
}
.review-box {
  text-align: center;
  padding: 33px 40px 40px 40px;
}
.review-box p {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 16px;
}
.review-box span {
  font-size: 15px;
  line-height: 28px;
  color: #CB3030CE;
  text-transform: uppercase;
  display: block;
}
.review-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-top: 11px;
  display: inline-block;
}

