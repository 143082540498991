#band {
    background-color:#9b111e;
    padding: 30px 0;
}

#band .band {
    /* border: 1px solid black; */
    width: 900px;
    margin: 0 auto;
    padding: 0 80px;
    display: grid;
    gap: .5rem;
    grid-template-columns: repeat(4, 1fr);
}

#band .band .item {
    padding: 30px 0;
    height: 220px;
    /* border: 1px solid white; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

#band .band .item .icon {
    height: 180px;
    width: 75px;
    border-radius: 30%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

#band .band .item .icon i {
    color: white;
    font-size: 40px;
}

#band .band .item h1 {
    font-size: 35px;
    color: white;
    font-weight: 500;
}

#band .band .item p {
    color: rgba(255, 255, 255, 0.648);
    font-size: 12px;
    font-weight: 400;
    margin-top: -10px;
}

@media (max-width: 490px) {
    #band .band {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 601px) {
    #band .band {
        padding: 0;
    }
}

@media (max-width: 1023px) {
    #band .band {
        width: 100%;
    }
}







