
::after,
::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

p {
  font-size: 15px;
  line-height: 24px;
  color: #777777;
  font-weight: normal;
  margin-bottom: 12px;
}
a {
  text-decoration: none;
  outline: none;
}

.sec-btn {
  height: 60px;
  display: inline-block;
  font-size: 15px;
  line-height: 30px;
  color: #ffffff;
  background-color: #9b111e;
  font-weight: 500;
  text-transform: uppercase;
  padding: 15px 32px;
  background-size: 400% 100%;
  background-position: left center;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  border: none;
  outline: none;
  transition: 0.3s;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-align: center;
}
.sec-btn:hover {
  background-position: right center;
  color: #ffffff;
}

@media screen and (min-width: 991px) {
  .sec-btn {
    margin-left: 0;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.sub-title {
  display: block;
  font-size: 18px;
  line-height: 28px;
  color: #9b111e;
  font-weight: bold;
  font-family: "Jost", sans-serif;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 10px;
}

.h1-title {
  text-align: center;
  font-size: 50px;
  line-height: 60px;
  color: #010f2e;
  font-weight: bold;
  font-family: "Jost", sans-serif;
  margin-bottom: 18px;
  max-width: 640px;
}

.h2-title {
  font-size: 34px;
  line-height: 44px;
  font-weight: bold;
  color: #010f2e;
  font-family: "Jost", sans-serif;
  margin-bottom: 30px;
}
.h3-title {
  font-size: 22px;
  line-height: 32px;
  color: #010f2e;
  font-weight: bold;
  font-family: "Jost", sans-serif;
  margin-bottom: 12px;
}
.h4-title {
  font-size: 35px;
  color: white;
  font-weight: bold;
  font-family: "jost", sans-serif;
  margin-bottom: 20px;
}

.form-box {
  display: block;
  position: relative;
  font-size: 0;
  line-height: 1;
  margin-bottom: 20px;
}
.form-input {
  width: 100%;
  height: 60px;
  background: #ffffff;
  font-size: 15px;
  line-height: 24px;
  color: #777777;
  border: none;
  outline: none;
  padding: 12px 30px;
}
@media screen and (max-width: 767px) {
  .form-input {
    height: 45px;
    padding: 12px 20px;
  }
}

@media screen and (min-width: 390px) {
  .sec-btn {
    font-size: 15px;
  }
}
@media screen and (min-width: 575px) {
  .h2-title {
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 30px;
  }
  .h4-title {
    font-size: 35px;
    line-height: 45px;
    color: #010f2e;

  }
}
@media screen and (min-width: 767px) {
  .sub-title {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .h1-title {
    font-size: 50px;
    line-height: 60px;
  }
  .h2-title {
    font-size: 48px;
    line-height: 58px;
  }
  .h3-title {
    font-size: 25px;
    line-height: 35px;
  }
  .h4-title {
    font-size: 40px;
    line-height: 50px;
  }
}
@media screen and (min-width: 991px) {
  .h1-title {
    font-size: 55px;
    line-height: 65px;
  }
  .h2-title {
    font-size: 52px;
    line-height: 62px;
  }
  .h4-title {
    font-size: 50px;
    line-height: 60px;
  }
}
@media screen and (min-width: 1199px) {
  .h1-title {
    font-size: 57px;
    line-height: 67px;
  }
  .h2-title {
    font-size: 65px;
    line-height: 75px;
  }
}
@media screen and (min-width: 1279px) {
  .h1-title {
    font-size: 68px;
    line-height: 78px;
    color: white;
  }
}
.back-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* pages */
.main {
  width: 100%;
  overflow-x: hidden;
}
.main .site-header {
  background: white;
}
.main-banner {
  position: relative;
}
.main-banner.inner-banner {
  margin-top: 0px;
  padding: 140px 0;
  background-size: 1600px;
  background-position: bottom center;
}
@media screen and (min-width: 767px) {
  .main-banner.inner-banner {
    padding: 140px 0 200px 0;
    background-size: 1920px;
  }
}
@media screen and (min-width: 991px) {
  .main-banner.inner-banner {
    padding: 210px 0 216px 0;
  }
}
.main-banner.inner-banner .banner-content {
  text-align: center;
  padding: 0;
}
.main-banner.inner-banner .banner-content .h1-title {
  color: #010f2e;
  margin: 0 auto 26px auto;
}
.breadcrumb-box {
  text-align: center;
}
.breadcrumb-box ul {
  font-size: 0;
  line-height: 1;
  list-style: none;
  margin-top: 0;
  margin-bottom: 1rem;
}
.breadcrumb-box ul li:first-child {
  padding-left: 0;
  margin-left: 0;
}

.breadcrumb-box ul li {
  font-size: 18px;
  line-height: 28px;
  color: #010f2e;
  display: inline-block;
  padding-left: 24px;
  margin-left: 14px;
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
}
.breadcrumb-box ul li:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  width: 10px;
  height: 10px;
  background: #010f2e;
  border-radius: 50%;
}
.breadcrumb-box ul li:first-child {
  padding-left: 0;
  margin-left: 0;
}
.breadcrumb-box ul li a {
  text-decoration: none;
  font-size: 18px;
  line-height: 28px;
  color: #010f2e;
  text-transform: uppercase;
  font-weight: 500;
  transition: 0.3s;
}

.breadcrumb-box ul li a:hover {
  color: #010f2e;
}



.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  padding: 10px 0;
  transition: 0.3s;
  z-index: 100;
}
.site-header.sticky-header {
  background: white;
  padding: 0px 0;
  box-shadow: 0 0 20px rgb(0 0 0/ 10%);
  transition: 0.3s;
}
.header-box {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.site-logo {
  display: inline-block;
}
.site-logo a {
  width: 100%;
}
.img-logo {
  height: 70px;
  max-width: 100%;
  vertical-align: middle;
}
.header-menu {
  display: inline-block;
  text-align: center;
  transition: 0.5s;
}
.header-menu-items {
  font-size: 0;
  line-height: 1;
  list-style: none;
  margin: 0;
  padding: 0;

}

.header-menu-items li {
  font-size: 16px;
  line-height: 26px;
  display: inline-block;
  position: relative;
  margin: 0 30px;
}
.header-menu-items li a {
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  color: #010f2e;
  font-weight: 600;
  font-family: "Jost", sans-serif;
  position: relative;
  transition: 0.3s;
}
.header-menu-items li .active,
.header-menu-items li a:hover {
  color: #811818FF;
}
.sub-items .sub-menu {
  position: absolute;
  top: 100%;
  left: -15px;
  width: 200px;
  height: auto;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 1px 1px 60px rgb(0 0 0 / 10%);
  display: none;
  transition: 0.3s;
  padding-left: 0;
}
.header-menu-items .sub-items .sub-menu li {
  display: block;
  text-align: left;
  margin: 0;
  border-bottom: 1px solid #ebebeb;
  padding: 5px 15px;
}
.show-pages .sub-menu{
  display: block;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: slide-up;
          animation-name: slide-up;
}
.show-about .sub-menu {
  display: block;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: slide-up;
          animation-name: slide-up;
}

@-webkit-keyframes slide-up {
  from {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
}

@keyframes slide-up {
  from {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
}

.bookings {
  background-color: #811818FF;
  color: white;
  padding: 10px 5px;
  border-radius: 8px;
  border-color: #811818FF;
  text-decoration: none;
  font-size: 50px;
  width: 100%;
  height: 50px;
}

#mybutton {
  position: fixed;
  bottom: 10px;
  right: 7px;

}
.search-icon a {
  display: inline-block;
  font-size: 20px;
  color: #777777;
  transition: 0.3s;
}

.extra-menu-icon {

  width: 100%;
  height: 60px;
  background-size: 400% 100%;
  background-position: left center;
  align-items: center;
  border-radius: 4px;
  transition: 0.3s;
  text-decoration: none;
  padding: 15px;
  text-align: center;
}

.toggle-btn {
  position: fixed;
  top: 0;
  right: 0;
  width: 70px;
  height: 70px;
  background: #811818FF;
  text-align: center;
  border: none;
  outline: none;
  display: none;
  z-index: 10;
}
.toggle-btn span {
  width: 30px;
  height: 3px;
  background: white;
  display: block;
  margin: 0 auto 6px auto;
  position: relative;
  top: 0;
  opacity: 1;
  transition: 0.3s;
}

@media screen and (max-width: 575px) {
}
@media screen and (max-width: 767px) {
}
@media screen and (max-width: 991px) {
  .nav {
    height: 70px;
    padding: 0;
    box-shadow: 0 0 20px rgb(0 0 0/10%);
  }

  .site-logo {
    position: fixed;
    top: 0;
    left: 30px;
    width: 200px;
    height: 70px;
    display: flex;
    align-items: center;
  }
  .toggle-btn {
    display: block;
  }

  .toggle-nav-menu .toggle-btn {
    display: block;
    background: transparent;
    width: 35px;
    height: 35px;
    top: 1rem;
    right: 1rem;
  }
  .toggle-nav-menu .toggle-btn span {
    background: #811818FF;
  }
  .toggle-nav-menu .toggle-btn span:nth-child(1) {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: relative;
    top: 5px;
  }
  .toggle-nav-menu .toggle-btn span:nth-child(2) {
    display: none;
  }
  .toggle-nav-menu .toggle-btn span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    position: relative;
    top: -4px;
  }

  .header-menu-items {
    position: fixed;
    top: 0;
    right: -400px;
    width: 320px;
    height: 100vh;
    overflow: auto;
    background: white;
    text-align: center;
    padding: 100px 0 30px;
    box-shadow: -30px 0 30px rgb(0 0 0 / 10%);
    transition: 0.5s;
  }
  .toggle-nav-menu .header-menu-items {
    -webkit-transform: translateX(-400px);
            transform: translateX(-400px);
  }
  .main-navigation .header-menu-items li {
    display: block;
    margin: -50px 0 10px 0;
    padding: 0 30px 10px;
    opacity: 0;
    transition: 0.5s;
  }
  .toggle-nav-menu .header-menu-items li {
    margin-top: 0;
    opacity: 1;
    transition: 0.5s;
    transition-delay: 0.4s;
  }
  .header-search {
    display: none;
  }
  .sub-items .sub-menu {
    background-color: #FA03233D;
    position: relative;
    width: 100%;
    margin-left: 1rem;
    transition: 0.3s;
  }
  .header-menu-items .sub-items .sub-menu li {
    text-align: center;
  }
}
@media screen and (max-width: 1199px) {
  .header-menu-items li {
    margin: 0 20px;
  }
}



.main-banner {
  background-color: #f8f9fa;
  margin-top: -90px;
  padding: 180px 0 0;
}


#home img{
  min-height: 639px;
  max-height: 639px;
}

.sec-btn-home {
  height: 60px;
  display: grid;
  font-size: 15px;
  line-height: 30px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 15px 32px;
  background-size: 400% 100%;
  background-position: left center;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  border: none;
  outline: none;
  transition: 0.3s;
  text-align: center;
  margin-top: -14px;
  background-color: #9b111e;
  text-decoration: none;
  color: whitesmoke;
  max-width: initial;
  margin-left: auto;
  margin-right: auto;

}


.carousel-item{
  width: 600px;
  max-width: 100%;
}
.banner-content {
  padding: 50px 0 15px;
}
.wel-span {
  color: black;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
  font-family: "Jost", sans-serif;
  text-transform: uppercase;
  position: relative;
  text-align: center;
}
.wel-h1 {
  text-align: center;
  font-size: 60px;
  line-height: 60px;
  color: #010f2e;
  font-weight: bold;
  font-family: "Jost", sans-serif;
  margin-bottom: 18px;
  max-width: 640px;
}
.wel-p1 {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 31px;
  color: #777777;
  font-weight: normal;
  text-align: center;
}

.banner-img {
  width: 100%;
  height: 100%;
  position: relative;
}

@media screen and (min-width: 767px) {
  .banner-content {
    padding: 90px 0 35px;
  }
  .wel-h1 {
    font-size: 50px;
    line-height: 60px;
  }
}
@media screen and (min-width: 991px) {
  .banner-content {
    padding: 0;
  }
  .wel-h1 {
    font-size: 55px;
    line-height: 65px;

  }
  .wel-span {
    text-align: left;
    font-size: 22px;
    margin: 2rem 0;
  }


}
@media screen and (min-width: 1199px) {
  .wel-h1 {
    font-size: 57px;
    line-height: 67px;
  }
}
@media screen and (min-width: 1279px) {
  .wel-h1 {
    font-size: 68px;
    line-height: 78px;
  }
}

@media screen and (max-width: 415px){
   #home img {
     min-height: 400px;
     max-height: 400px;

   }

}

.main-our-services {
  padding-top: 51px;
}
.our-services-title {
  text-align: center;
}
.sub-serv-title {
  display: block;
  font-size: 18px;
  line-height: 28px;
  color: #9b111e;
  font-weight: bold;
  font-family: "Jost", sans-serif;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 10px;
}
.h2-serv-title {
  font-size: 65px;
  line-height: 75px;
  font-size: 35px;
  line-height: 45px;
  font-weight: bold;
  color: #010f2e;
  font-family: "Jost", sans-serif;
  margin-bottom: 30px;
}
.our-service-box {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 35px 25px;
  text-align: center;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 1px 1px 60px rgb(0 0 0 / 8%);
  overflow: hidden;
  margin-bottom: 30px;
}
.our-service-text {
  position: relative;
}
.our-service-text-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-bottom: 25px;
  transition: 0.3s;
}

.svg-inline--fa {
  height: 3em;
  overflow: visible;
  vertical-align: -0.125em;
}

.our-service-text-icon img {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border: 2px solid black;
  border-radius: 4px;
  margin-bottom: 25px;
  transition: 0.3s;

}
.our-serv-icon-h3 {
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 10px;
  color: #010f2e;
  font-weight: bold;
  font-family: "Jost", sans-serif;
  transition: color 0.1s ease-in-out;
}
.our-serv-icon-p {
  font-size: 15px;
  line-height: 24px;
  color: #777777;
  font-weight: normal;
  margin-bottom: 12px;
  transition: color 0.1s ease-in-out;
  height: 100%;
  max-height: 100%;
}
.our-service-box:hover .our-service-text-icon {
  -webkit-animation-name: icon-scale;
          animation-name: icon-scale;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.our-service-box:hover .our-serv-icon-h3 {
  color: #010f2e;
}
.our-service-box:hover .our-serv-icon-p {
  color: #777777;
}
.serv-btn{
  height: 30px;
  display: inline-block;
  font-size: 13px;
  text-decoration: none;
  font-weight: 500;
  text-transform: uppercase;
  padding: 5px ;
  width: 87%;
  background-color: #9b111e;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  transition: 0.3s;
  margin-left: 50%;
  text-align: center;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);


}

.serv-btn:hover {
  background-position: right center;
  color: #ffffff;
  text-decoration: none;
}

@media screen and (min-width: 991px) {
  .serv-btn {
    margin-left: 0;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}


@-webkit-keyframes icon-scale {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


@keyframes icon-scale {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.our-service-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  width: 100%;
  height: 0;
  border-radius: 4px;
  transition: 0.5s;
  z-index: 0;
}

.our-service-box:hover::before {
  height: 0;
  bottom: 0;
  top: auto;
  height: 100%;
}

@media screen and (min-width: 575px) {
  .main-our-services {
    padding-top: 71px;
  }
  .h2-serv-title {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 767px) {
  .our-serv-icon-h3 {
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 12px;
  }
  .main-our-services {
    padding-top: 71px;
  }
  .sub-serv-title {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .h2-serv-title {
    font-size: 48px;
    line-height: 58px;
  }
}
@media screen and (min-width: 991px) {
  .main-our-services {
    padding-top: 111px;
  }
  .h2-serv-title {
    font-size: 52px;
    line-height: 62px;
  }
}
@media screen and (min-width: 1199px) {
  .h2-serv-title {
    font-size: 65px;
    line-height: 75px;
  }
}


@media screen and (min-width: 1400px) {
  .our-serv-icon-p {
    text-align: justify-all;
    width: 280px;
    max-height: 100%;
    margin-bottom: 20px;
    padding-bottom: 10px;
    margin-right: 0;
    margin-left: -12px;
  }
  .serv-btn{
    -webkit-transform: translateY(-40%);
            transform: translateY(-40%);
  }

  .our-service-box {
    margin-bottom: -20px;
    padding-top: 10px;
    max-height: 95%;
    margin-left: 0;
    margin-right: 0;
  }
  .our-serv-icon-h3 {
    margin-bottom: 5px;
    margin-top: -20px;

  }
  .our-service-text-icon img{
    margin-bottom: 5px;
  }

}
.main-about-us {
  position: relative;
  padding: 0 0 60px 0;
}
.about-img-box img {
  width: 100%;
}
.about-text {
  margin-bottom: 36px;
}

@media screen and (min-width: 767px) {
  .main-about-us {
    position: relative;
    padding: 0 0 80px 0;
  }
}
@media screen and (min-width: 991px) {
  .main-about-us {
    position: relative;
    padding: 80px 0 120px 0;
  }
  .about-img-box img {
    width: 100%;
    position: relative;
    right: 100px;
    height: 55%;
  }
}
@media screen and (min-width: 1199px) {
}
@media screen and (min-width: 1279px) {
}

#main-faq {
  background-color: #777777;
  padding: 40px 0;
}

#main-faq .sub-faq{
  width: 900px;
  padding: 0 80px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 350px .9fr;
  grid-gap: 2rem;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  height: 390px;
}

#main-faq .sub-faq .faq-content-left, #main-faq .sub-faq .faq-content-right {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.faq-content-left .sub-title {
  color: white;
  margin-bottom: 17px;
}
.faq-content-left .h4-title {
  color: white;
  margin-bottom: 20px;
}

.faq-content-left p {
  margin-bottom: 0px;
  font-size: 20px;
  color: #24252aa3;
  display: block;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 0em;
          margin-block-end: 0em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  vertical-align: center;
}
#main-faq .sub-faq .faq-content-right{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: .5rem;
  gap: .5rem;
}
#main-faq .sub-faq .faq-content-right .box {
  height: 70px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 0px 20px;
  transition: all .3s ease;
}

#main-faq .sub-faq .faq-content-right .box .title-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

#main-faq .sub-faq .faq-content-right .box .title-box h1{
  font-size: 17px;
  vertical-align: center;
  font-family: "Jost", sans-serif;

}

#main-faq .sub-faq .faq-content-right .box  p {
  font-size: 14px;
  text-align: match-parent;
  color: whitesmoke;
  font-family: arial, Verdana;
  margin-top: 15px;
}

#main-faq .icon,  #main-faq .sub-faq .faq-content-right .box:hover .title-box h1{
  color: black;
}

#main-faq .sub-faq .faq-content-right .box:hover{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #9b111e;
}

@media (max-width: 244px) {
  #main-faq .sub-faq .faq-content-right .box:nth-child(2) {
    padding: 15px 20px;
  }
}

@media (min-width: 245px) and (max-width: 308px) {
  #main-faq .sub-faq .faq-content-right .box {
    padding: 15px 20px;
  }
  #main-faq .sub-faq .faq-content-right .box:nth-child(2) {
    padding: 26px 20px;
  }
}

@media (max-width: 367px) {
  #main-faq .sub-faq {
    padding: 0 10px;
  }
}

@media (min-width: 368px) and (max-width: 468px) {
  #main-faq .sub-faq {
    padding: 0 30px;
  }
}

@media (max-width: 696px) {
  #main-faq .sub-faq {
    grid-template-columns: 1fr;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

@media (min-width: 697px) and (max-width: 833px) {
  #main-faq .sub-faq {
    padding: 0 20px;
  }
}

@media (max-width: 1023px) {
  #main-faq .sub-faq {
    width: 100%;
  }
}

@media screen and (min-width: 991px) {
}
@media screen and (min-width: 1199px) {
}
@media screen and (min-width: 1279px) {
}

.site-footer {
  position: relative;
  background: #1c233d;
  color: white;
  padding: 60px 0;
}
.footer-info {
  background: #fff;
  padding-top: 60px;
  padding-bottom: 10px;
  max-height: 100%;
  text-align: center;
  margin-top: -140px;
  border: 2px solid #1c233d;
  height: 600px;
  width: 270px;
  max-width: 100%;
  min-width: 100%;


}
.footer-logo img {
  height: 120px;
  position: relative;
  margin-top: -10px;
  margin-bottom: 25px;
}
.footer-info p {
  text-align: center;
  font-family: "Jost", sans-serif;
  flex-wrap: wrap;
  vertical-align: center;
  margin: -40px 0px 30px;
  color: #575555;
}


.social-icons a {
  width: 41px;
  height: 41px;
  background: black;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #ffffff;
  border-radius: 50%;
  margin-right: 14px;
  position: relative;
  transition: 0.3s;
}

.social-icons a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #ab0016, #CB3030CE);
  border-radius: 50%;
  opacity: 0;
  transition: 0.3s;
}
.social-icons a:hover::before {
  opacity: 1;
}
.social-icon {
  z-index: 1;
  color: white;
}
.h3-title.footer-title {
  color: white;
  position: relative;
  padding-bottom: 2px;
  margin-bottom: 26px;
}
.h3-title.footer-title::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 70px;
  height: 1px;
  background: linear-gradient(to right, #ab0016, #CB3030CE);
}
.footer-contact {
  padding-bottom: 1.5rem;
  margin-left: 30px;
}
.footer-contact-box {
  min-height: 40px;
  margin-bottom: 12px;
}
.footer-contact-link {
  display: inline-block;
  padding-left: 51px;
  position: relative;
}
.footer-contact-link .icon {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 40px;
  height: 40px;
  background: linear-gradient(to right, #ab0016, #CB3030CE);
  border-radius: 50%;
  color: #ffffff;
  font-size: 20px;
}
.footer-contact-link a {
  display: block;
  color: #a9aebe;
  font-size: 15px;
  line-height: 25px;
  transition: 0.3s;
  text-decoration: none;
}

.footer-contact-link a:hover {
  color: #ab0016;
}
.our-links {
  padding-left: 0px;
  padding-bottom: 1.5rem;
}
.our-links ul,
.our-services ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0;
  line-height: 1;
  -webkit-column-count: 2;
          column-count: 2;

}
.our-services ul {
  -webkit-column-count: 1;
          column-count: 1;
}

.our-links ul li {
  display: block;
  color: #a9aebe;
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 6px;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;

}
.our-links ul li a{
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
  text-decoration: none;
  color: #a9aebe;
}



.site-footer li:hover {
  color: #ab0016;
}

.footer-last {
  background: #10152a;
  padding: 30px 0;
}
.copy-right {
  text-align: center;
  margin-bottom: 10px;
}
.copy-right p {
  color: white;
  margin-bottom: 0;
}
.footer-last-link {
  text-align: center;
  padding-right: 1rem;
}
.footer-last-link ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0;
  line-height: 1;
}
.footer-last-link ul li {
  display: inline-block;
  font-size: 15px;
  line-height: 25px;
  padding-left: 20px;
  position: relative;
  margin-left: 1rem;
}
.footer-last-link a {
  text-decoration: none;
  color: white;
}
.footer-last-link ul li::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  left: 0;
  width: 8px;
  height: 8px;
  background: #ab0016;
  border-radius: 50%;
}

@media screen and (min-width: 575px) {
}
@media screen and (min-width: 767px) {
  .site-footer {
    padding: 80px 0;
  }
}
@media screen and (min-width: 991px) {
  .site-footer {
    padding: 120px 0 100px 0;
  }
  .footer-info {
    padding-bottom: 0px;
  }
  .footer-contact {
    padding-bottom: 0px;
  }
  .our-links {
    padding-left: 30px;
    padding-bottom: 0px;
  }
  .footer-last {
    padding: 22px 0;
  }
  .copy-right {
    text-align: left;
    margin-bottom: 0;
  }
  .footer-last-link {
    text-align: right;
    padding-right: 0px;
  }
  .footer-last-link ul li {
    margin-left: 33px;
  }
}
@media screen and (min-width: 1199px) {
  .our-links {
    padding-left: 60px;
  }
}

.main-pricing {
  position: relative;
  padding-bottom: 40px;
}
.pricing-title {
  text-align: center;
}

.pricing-box {
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  text-align: center;
  transition: 0.4s;
}
.pricing-box:hover {
  background-color: rgba(255, 80, 102, 0.1);
}

.pricing-box-text {
  position: relative;
  padding: 34px 40px 40px 40px;
  z-index: 1;
}
.pricing-box-text .h4-title {
  color: #fc9d44;
  margin-bottom: 23px;
}
.pricing-box-text .h4-title span {
  font-size: 18px;
  line-height: 28px;
  color: #010f2e;
  font-weight: 600;
}
.pricing-box-text .h3-title {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 20px;
}
.pricing-img img {
  width: auto;
  margin: 0 auto;
  height: 100px;
  display: inline-block;
  margin-bottom: 22px;
}
.pricing-text-list {
  padding-top: 29px;
  border-top: 1px solid #cccccc;
  text-align: left;
}
.pricing-text-list ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 34px;
}
.pricing-text-list ul li {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 18px;
  position: relative;
  padding-left: 41px;
}
.pricing-text-list ul li::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 0px;
  width: 26px;
  height: 26px;
  background-image: url(/static/media/check.c87ba8c8.png);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.pricing-text-list-comm .commercial::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 0px;
  width: 26px;
  height: 26px;
  background-image: url(/static/media/check.c87ba8c8.png);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.pricing-text-list ul .disabled {
  background: linear-gradient(to right, #696969, #ffffff);
  opacity: 0.3;
}
@media screen and (max-width: 991px) {
  .pricing-box .sec-btn {
    margin-left: 23%;
  }
}
@media screen and (max-width: 767px) {
  .pricing-box .sec-btn {
    margin-left: 33%;
  }
}
@media screen and (max-width: 576px) {
  .pricing-box .sec-btn {
    margin-left: 38%;
  }
}

.main-team {
  position: relative;
  padding: 83px 0 40px 0;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .main-team {
    padding: 26px 0 30px 0;
  }
}
@media screen and (max-width: 991px) {
  .main-team {
    padding: 46px 0 20px 0;
  }
}
@media screen and (max-width: 400px) {
  .main-team {
    padding: 26px 0 30px 0;
  }
  .team-img {
    width: 100%;
    height: 285px;
    max-height: 285px;
    transition: 0.3s;
    background-position: center;
    background-size: cover, 90%;
    background-repeat: no-repeat;
    background-position-y: -30px;
  }


}
.team-title {
  text-align: center;
}
.team-box {
  position: relative;
  overflow: hidden;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 2px 2px 60px rgb(0 0 0 / 8%);
  overflow: hidden;
  transition: 0.3s;
  margin: 5px 15px;
}
@media screen and (max-width: 576px) {
  .team-box {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

.team-text {
  position: relative;
  z-index: 1;
}



.team-img {
  width: 100%;
  height: 285px;
  max-height: 285px;
  transition: 0.3s;
  background-position: center;
  background-size: cover, 90%;
  background-repeat: no-repeat;
  background-position-y: -30px;
}

.team-text-box {
  position: relative;
  padding: 24px 30px 21px 30px;
}
.team-text-box p {
  color: #9b111e;
  text-transform: uppercase;
  margin-bottom: 2px;
  transition: 0.3s;
}
.team-text-box .h3-title {
  margin-bottom: 0;
  transition: 0.3s;
}



.main-experience {
  position: relative;
  padding: 60px 0 46px 0;
  overflow: visible;
}
.provider-experience{
  margin-bottom: 70px;
}

@media screen and (min-width: 767px) {
  .main-experience {
    padding: 80px 0 66px 0;
  }
}
@media screen and (min-width: 991px) {
  .main-experience {
    padding: 120px 0 113px 0;
  }
}

.experience-skills {
  margin-top: 35px;
}
.experience-skill-bar-box {
  position: relative;
  margin-bottom: 20px;
}
.h3-title.experience-skill-bar-title {
  margin-bottom: 9px;
}

.experience-skill-list ul {
  margin-right: 100px;
  min-width: 100%;
  width: 100%;
}

.experience-skill-list ul li{
  position: relative;
  font-size: 13px;
  color: #777777;
  font-weight: bold;
  white-space: normal;
  line-height: 20px;
  margin-left: -20px;
}


.experience-skill-list ul li span{
  display: inline-flex;
  margin-left: -.25em;
  line-height: 16px;

}



.experience-skill-bar:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  width: 100%;
  height: 8px;
  background: #ebebeb;
  border-radius: 5px;
}
.experience-skill-bar-inner {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  width: 0;
  height: 22px;
  border-radius: 5px;
  overflow: visible !important;
}
.experience-skill-bar-inner:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  width: 100%;
  height: 8px;
  background: #fc9d44;
  box-shadow: -6px 6px 30px rgb(252 157 68 / 40%);
  border-radius: 5px;
}
@media screen and (min-width: 991px) {
  .experience-skill-bar-box {
    position: relative;
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 768px) {
  .experience-skill-list p li{
   flex-wrap: wrap;

  }
}

.experience-img {
  position: relative;
}
.experience-img img {
  vertical-align: middle;
  max-width: 100%;
  margin-left: 0px;
}

#band {
    background-color:#9b111e;
    padding: 30px 0;
}

#band .band {
    /* border: 1px solid black; */
    width: 900px;
    margin: 0 auto;
    padding: 0 80px;
    display: grid;
    grid-gap: .5rem;
    gap: .5rem;
    grid-template-columns: repeat(4, 1fr);
}

#band .band .item {
    padding: 30px 0;
    height: 220px;
    /* border: 1px solid white; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

#band .band .item .icon {
    height: 180px;
    width: 75px;
    border-radius: 30%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

#band .band .item .icon i {
    color: white;
    font-size: 40px;
}

#band .band .item h1 {
    font-size: 35px;
    color: white;
    font-weight: 500;
}

#band .band .item p {
    color: rgba(255, 255, 255, 0.648);
    font-size: 12px;
    font-weight: 400;
    margin-top: -10px;
}

@media (max-width: 490px) {
    #band .band {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 601px) {
    #band .band {
        padding: 0;
    }
}

@media (max-width: 1023px) {
    #band .band {
        width: 100%;
    }
}








.main-testimonial {
  position: relative;
  padding: 90px 0;
}
@media screen and (max-width: 991px) {
  .main-testimonial {
    padding: 73px 0;
  }
}
@media screen and (max-width: 767px) {
  .main-testimonial {
    padding: 53px 0;
  }
}

.main-testimonial-slider {
  position: relative;
  margin-bottom: 40px;
}

@media screen and (max-width: 991px) {
  .main-testimonial-slider {
    margin-top: 40px;
  }
}

.testimonial-slider-box {
  width: 100%;
  height: auto;
  background: #ffffff;
  border-radius: 4px;
  border: solid 2px transparent;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at bottom, #ab0016, rgba(203, 48, 48, 0.81));
  background-origin: border-box;
  background-clip: content-box, border-box;
  position: relative;
  box-shadow: 2px 2px 60px rgba(115, 57, 57, 0.78);
  z-index: 1;
}
.main-testimonial-slider::before,
.main-testimonial-slider::after {
  content: "";
  position: absolute;
  top: 20px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  width: calc(100% - 44px);
  height: 100%;
  border-radius: 4px;
  border: solid 2px #ab0016;
  opacity: 0.4;
  z-index: 0;
}
.main-testimonial-slider::after {
  top: 40px;
  width: calc(100% - 84px);
}
.review-box {
  text-align: center;
  padding: 33px 40px 40px 40px;
}
.review-box p {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 16px;
}
.review-box span {
  font-size: 15px;
  line-height: 28px;
  color: #CB3030CE;
  text-transform: uppercase;
  display: block;
}
.review-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-top: 11px;
  display: inline-block;
}


.main-contact-us {
  position: relative;
  padding-top: 115px;
  margin-bottom: -150px;
  z-index: 1;
}

@media screen and (max-width: 991px) {
  .main-contact-us {
    padding-top: 80px;
  }
}
@media screen and (max-width: 767px) {
  .main-contact-us {
    padding-top: 60px;
  }
}

.contact-us-content {
  width: 100%;
  background: #ffffff;
  position: relative;
  padding: 53px 60px 40px 60px;
  border-radius: 4px;
  box-shadow: 1px 1px 40px rgb(0 0 0 / 8%);
  text-align: center;
  margin-bottom: 250px;
}

.contact-us-content .contact-form .form-input {
  border: 1px solid #343a40;
  border-radius: 4px;
}

.contact-us-content .form-box .sec-btn {
  width: 100%;
}

.empty-contact-us-div {
  width: 100%;
  height: 250px;
  background: linear-gradient(to right, #ab0016, #CB3030CE, #CB3030CE, #ab0016);
  opacity: 0.9;
  margin-bottom: -33px;
}

.history-section {
    position        : relative;
    background-color: #9b111e;
}

.history-section .tab-content .sec-title h2 {
    font-size     : 48px;
    letter-spacing: 0px;
}

.history-section .tab-content .sec-title {
    margin-bottom: 10px;

}

.history-section .tab-content .text {
    color        : #fff;
    margin-bottom: 35px;
    margin-right: 30px;
}

.history-section .tab-btn-style-one {
    justify-content : space-between;
    border-bottom   : 0;
    margin-bottom   : 60px;
    grid-gap: 1.2em;
}


.history-section .tab-btn-style-one span{
    display:inline-grid;
    position: relative;
    padding: 0 10px;

}

.history-section .tab-btn-style-one .nav-item .nav-link.active {
    color                  : #fff;
    -webkit-text-fill-color: inherit;
    background-clip        : inherit;
    border: 2px solid white;
    position: relative;
}

.history-section .sec-title .title {
    -webkit-text-fill-color: inherit;
    margin-bottom: -6px;
    font-size: 80px;
    color: antiquewhite;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    position: relative;
    font-family: "Jost", sans-serif;



}

.history-section .tab-btn-style-one .nav-item .nav-link {
    border-color           : transparent;
    background: #282828;
}

.history-section .tab-btn-style-one .nav-item .nav-link.active:before {
    display: none;
}

.history-section .tab-btn-style-one .nav-item .nav-link {
    color        : #fff;
    padding      : 15.5px 45px;
    border-radius: 30px;
    font-size    : 16px;
    background   : #282828;

}

.history-section .image img {
    position: relative;
    margin-top: -20px;
    margin-bottom: 20px;
    margin-left: 0px;
    width: 500px;
    max-width: 100%;
    height: auto;
}

.history-section .content {
    margin-bottom: 30px;
}

.fadeInUp-animated{
    -webkit-animation: slide-in 1s;
            animation: slide-in 1s;
}

@-webkit-keyframes slide-in{
    from {
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
    }

    to {
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

@keyframes slide-in{
    from {
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
    }

    to {
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}


.main-portfolio {
  position: relative;
  display: grid;
}
@media screen and (max-width: 991px) {
  .main-portfolio {
    padding-top: 73px;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .main-portfolio {
    padding-top: 53px;
  }
}

.portfolio-title .sub-title {
  margin-bottom: 10px;
  margin-left: -40px;
}

.portfolio-title .h2-title{
  margin-left: -40px;

}
.portfolio-tabbing ul {
  margin-right: -7px;
  padding: 2px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.portfolio-tabbing ul li .filter {
  display: inline-block;
  font-weight: 500;
  transition: 0.3s;
  cursor: pointer;
}
.portfolio-tabbing ul li .filter:hover,
.portfolio-tabbing ul li .filter.active {
  color: #811818FF;
  text-decoration: underline;
}

.portfolio {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  padding: 0 15px;
  display: none;
}
.portfolio.md-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.portfolio.md-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
@media screen and (max-width: 991px) {
  .portfolio.md-6,
  .portfolio.md-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media screen and (max-width: 575px) {
  .portfolio.md-6,
  .portfolio.md-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.portfolio-wrapper {
  width: 100%;
  height: 370px;
  position: relative;
  box-shadow: 2px 2px 60px rgb(0 0 0 / 10%);
  border-radius: 4px;
  margin-bottom: 30px;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .portfolio-wrapper {
    height: 300px;
  }
}
@media screen and (max-width: 575px) {
  .portfolio-wrapper {
    height: 250px;
  }
}
.portfolio-wrapper .portfolio-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}
.portfolio-wrapper-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px;
  text-align: center;
  opacity: 0;
  transition: 0.5s;
  z-index: 5;
}
.portfolio-wrapper-text .h3-title {
  color: #ffffff;
  font-weight: 800;
  position: relative;
  top: -10px;
  margin-bottom: 0;
  opacity: 1;
  transition: 0.5s;
}
.portfolio-wrapper-text p {
  color: #ffffff;
  margin-bottom: 23px;
  position: relative;
  top: -10px;
  opacity: 1;
  transition: 0.5s;
}
.portfolio-wrapper::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.5s;
  z-index: 1;
}
.portfolio-wrapper:hover .portfolio-wrapper-text {
  opacity: 1;
  transition-delay: 0.3s;
}
.portfolio-wrapper:hover::before {
  opacity: 0.7;
  transition-delay: 0.3s;
}
.portfolio-wrapper:hover .portfolio-img {
  width: 115%;
  height: 115%;
}

.contact-page-content {
  position: relative;
  padding: 48px 0 60px 0;
}
@media screen and (min-width: 991px) {
  .contact-page-content {
    padding: 85px 0 120px 0;
  }
}

.contact-page-form .contact-form .form-input {
  border: 1px solid #343a40;
  border-radius: 4px;
}

.contact-page-form .contact-form .sec-btn {
  margin-left: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
@media screen and (min-width: 991px) {
  .contact-page-img img {
    height: 534px;
    width: 580px;
    float: right;
    position: relative;
    right: -30px;
  }
}

.contact-page-link {
  padding-top: 0;
}
@media screen and (min-width: 991px) {
  .contact-page-link {
    padding-top: 46px;
  }
}
.contact-link-box {
  position: relative;
  width: 100%;
  padding: 35px 30px;
  margin-bottom: 30px;
  text-align: center;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 1px 1px 60px rgb(0 0 0 / 8%);
  overflow: hidden;
}
@media screen and (min-width: 991px) {
  .contact-link-box {
    padding: 35px 25px;
    margin-bottom: 0;
  }
}
.contact-link-text {
  position: relative;
  z-index: 1;
}
.contact-link-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background: #ffffff;
  border: 2px solid #801818;
  border-radius: 4px;
  margin-bottom: 25px;
  transition: 0.3s;
}
.contact-link-text p {
  margin-bottom: 0;
  transition: 0.3s;
}
.contact-link-text p a {
  display: inline-block;
  color: black;
  transition: 0.3s;
  text-decoration: none;
}

.contact-link-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  width: 100%;
  height: 0;
  background: linear-gradient(to right, #ab0016, #CB3030CE);
  border-radius: 4px;
  transition: 0.5s;
  z-index: 0;
}
.contact-link-box:hover::before {
  height: 100%;
  top: auto;
  bottom: 0;
}

.contact-map {
  width: 100%;
  height: 320px;
  position: relative;
  z-index: 0;
}
@media screen and (min-width: 767px) {
  .contact-map {
    height: 450px;
  }
}
@media screen and (min-width: 991px) {
  .contact-map {
    height: 550px;
  }
}
@media screen and (min-width: 1199px) {
  .contact-map {
    height: 550px;
  }
}
.contact-page-empty-div {
  height: 40px;
  background: rgba(128, 128, 128, 0.1);
  margin-bottom: -33px;
}

.sec-btn {
  width: 50%;
}
.cards{
    position     : relative;
    margin-bottom: 30px;
    display: inline-block;
    width: 600px;
    min-width: 600px;
    max-width: 100%;


}
.faq__question-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 600px;
    max-width: 100%;
    position: relative;
    padding-top       : 0px;
    margin-bottom     : 25px;
    box-shadow        : 0px 5px 21.25px 3.75px rgba(217, 217, 217, 0.65);
}

.faq__question{
     margin: 1rem;
     margin-right: 0;
     font-size: 1.3rem;
     text-align: left;
     flex-basis: 90%;
 }

.faq__answer-container{

    background: rgba(156, 17, 31, 100);
    opacity: 0.6;
     padding: 5%;
     width: 100%;
     border-radius: 0 0 10px 10px;
     text-align: left;
     color: whitesmoke;

 }


.faq__answer{
    position          : relative;
    font-size         : 18px;
    cursor            : pointer;
    line-height       : 1.2em;
    color             : black;
    font-weight       : 700;
    padding           : 28.5px 30px;
    padding-left      : 58px;
    transition        : all 500ms ease;
    -ms-transition    : all 500ms ease;
    -o-transition     : all 500ms ease;
    -moz-transition   : all 500ms ease;
    -webkit-transition: all 500ms ease;
 }
.arrows{
    margin-right: 50px;
}

#faq-button{
    display: block;
    text-decoration: none;
    border-top: solid 1px #2c3034;
    border-bottom: solid 1px #2c3034;
    padding: 0 1rem;
    margin: 1rem auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

#faq-button:link{
    color: #2c3034;
}
#faq-button:visited{
    color:yellow;
}

#faq-button:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0 0 4px hsla(0,0%, 0%, .3);
}

@media screen and (max-width:767px) {
    .faq__question-container{
        align-items: center;
        max-width: 50%;
        margin-right: auto;
        flex-shrink: 50;
        padding-right: 20px;
    }

    .faq__question{
        margin-right: 8px;
        font-size: 1.3rem;
        text-align: center;
    }

    .faq__answer{
        font-size: 1.3rem;
        text-align: justify-all;
    }
    .faq__answer-container{
        max-width: 50%;
        padding: 0;
        align-items:center;

    }




}


.faq {
    margin: 23vh auto 10vh;
    max-width: 1200px;
}

.h1-title {
    text-align: center;
    font-size: 48px;
    line-height: 60px;
    color: #010f2e;
    font-weight: bold;
    font-family: "Jost", sans-serif;
    margin-bottom: 18px;
    max-width: 640px;
}


.faq-arrow{
    position          : relative;
    font-size         : 18px;
    cursor            : pointer;
    line-height       : 1.2em;
    color             : #222;
    font-weight       : 400;
    padding           : 28.5px 30px;
    padding-left      : 58px;
    transition        : all 500ms ease;
    -ms-transition    : all 500ms ease;
    -o-transition     : all 500ms ease;
    -moz-transition   : all 500ms ease;
    -webkit-transition: all 500ms ease;
  }

.faq-bottom-line{
    margin: 3rem 0 5rem;
}

.banner-content {
    padding: 50px 0 15px;
}


.service-news-letter {
  padding: 80px 0;
}
@media screen and (max-width: 576px) {
  .service-news-letter {
    padding: 60px 0;
  }
}

.news-section {
    position: relative;
    padding : 120px 0 70px;
}

.news-section .sec-bg {
    position       : absolute;
    left           : 0;
    top            : 0;
    width          : 100%;
    height         : 605px;
    background-size: cover;
}

.news-section .sec-bg:before {
    position        : absolute;
    content         : '';
    background-color: #282828;
    left            : 0;
    top             : 0;
    width           : 100%;
    height          : 100%;
    opacity         : .9;
}

.news-block-one {
    position: relative;
}

.news-block-one .inner-box {
    position     : relative;
    margin-bottom: 30px;
}

.news-block-one .image {
    position: relative;
    overflow: hidden;
}

.news-block-one .image img {
    width             : 100%;
    transition        : 1s;
    max-height: 1.0in;
    min-height: 2.6in;
    max-width: 100%;
    display: block;
}

.news-block-one .inner-box:hover .image img {
    -webkit-transform: scale(1.2);
    transform        : scale(1.2);
}

.news-block-one .lower-content {
    position          : relative;
    padding           : 0 20px 20px;
    box-shadow        : 0px 8px 16px 0px rgba(40, 40, 40, 0.1);
    background-color  : #fff;
    max-height: 200px;
    min-height: 200px;
}


.news-block-one .category {
    position         : relative;
    font-size        : 14px;
    font-weight      : 700;
    padding          : 6.5px 20px;
    color            : #fff;
    border-radius    : 20px;
    margin: 0 0 0 auto;
    -webkit-transform: translateY(-50%);
    transform        : translateY(-50%);
}

.news-block-one .post-meta {
    position         : relative;
    display          : flex;
    flex-wrap        : wrap;
    align-items      : center;
    margin-bottom    : 10px;
}

.news-block-one .post-meta li a {
    font-size  : 14px;
    font-weight: 700;
    color      : #282828;
}

.news-block-one .post-meta li {

    text-align: center;
    margin-left: 75px;
    list-style: none;
}

.news-block-one .post-meta li a icon {
    position    : relative;
    margin-right: 5px;
}

.news-block-one h3 {
    font-size    : 22px;
    font-weight  : 700;
    margin-bottom: 15px;
    text-align: center;
}

.news-block-one h3 a {
    color             : #282828;
    transition        : .5s;
    text-align: center;
}


.btn-blog{
    text-align: center;
    margin-top: 10px;
    background-color: #dadada;
    border: #2c3034 3px;
    text-decoration: none;
    height: 60px;
    display: inline-block;
    font-size: 15px;
    line-height: 30px;
    color: black;
    font-weight: 500;
    text-transform: uppercase;
    padding: 15px 32px;
    background-size: 400% 100%;
    background-position: left center;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    outline: none;
    transition: 0.3s;
    margin-left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);

}

.btn-blog:hover {
    background-position: right center;
    color: black;
}


.portfolio-details {
    position: relative;
    padding : 120px 0 90px;
}

.image-box-blog img {
    display: block;
    max-width: 100%;
    width: 400px;
    height: auto;
    vertical-align: middle;
    border-style: none;
    outline: none;
    margin-left: auto;
   margin-right: auto;

}

.portfolio-details .text {
    margin-bottom: 30px;
}
.portfolio-details .text a {
    margin-bottom: 30px;
    text-decoration: none;
}


.portfolio-details .sec-title{
    text-align: center;
}

/* Project Post Pagination */

.project-post-pagination {
    position      : relative;
    margin-bottom : 40px;
    border-bottom : 1px solid #ebebeb;
    padding-bottom: 40px;
}

.project-post-pagination .wrapper-box {
    position         : relative;
    display          : flex;
    align-items      : center;
    justify-content  : space-between;
    flex-wrap        : wrap;
    margin: auto;
}

.project-post-pagination .next-post,
.project-post-pagination .prev-post {
    position: relative;
    border  : 2px solid #ebebeb;
    padding : 19px 30px 20px;
    cursor  : pointer;

}

.project-post-pagination h5 {
    font-size  : 14px;
    font-weight: 400;
    line-height: 1em;
    display    : inline-block;
    color      : #282828;
}
.project-post-pagination h5 a {
    -webkit-text-decoration: black;
            text-decoration: black;
    color: black;
}

.project-post-pagination h4 {
    font-size  : 24px;
    font-weight: 700;
}

.project-post-pagination .next-post {
    text-align: right;
}

.project-post-pagination .page-view {
    width      : 100px;
    height     : 100px;
    font-size  : 30px;
    line-height: 100px;
    text-align : center;
    color      : #fff;
    cursor     : pointer;
}

/** FAMILY PLANNING BLOG CSS **/
table{

    border: 2px solid #8f8f8f;
    letter-spacing: 1px;
    margin-bottom: 20px;
    font-family: "Roboto Condensed",Helvetica,Arial,sans-serif;
    font-size: 0.875rem;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
}




td{
    vertical-align: bottom;
    border: 1px solid rgb(190, 190, 190);
    padding: 8px;
    line-height: 1.42857;
    text-align: left;
    position: static;
    display: flex;
    float: none;
}


th{
    position: static;
    display: table-cell;
    float: none;
    border: 1px solid rgb(190, 190, 190);
    padding: 5px 10px;

}

.img-responsive {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-width: 50%;
    height: auto;
    vertical-align: middle;
    border: 0;

}






.sidebar-page-container {
    position: relative;
    padding : 120px 0 90px;
    border-color: #2c3034;
    border-width: 1px;
}

.auto-container {
    padding  : 0px 15px;
    margin   : 0 auto;
}

.categories-widget-two {
    margin-bottom: 30px;
    box-shadow        : 0px 8px 16px 0px rgb(155, 152, 152);


    vertical-align: middle;
    background: linear-gradient(
            to top,
            white 0%,
            white 83%,
            rgb(128, 24, 24) 50%,
            rgb(128, 24, 24)100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
}

.heading-8{
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 40px;
    color: white;
}

.categories-widget-two ul {
    position: relative;
    list-style: none;
    padding: 0px;
    margin: 2em 0;
}


.categories-widget-two li {
    font-family: "Jost", sans-serif;
    color: #010f2e;
    font-size: 20px;
    letter-spacing: .75px;
}

.categories-widget-two li icon{
    height: 17px;
    padding: 0 10px 0 0;
    verticalAlign: 0.2;
}



/* Services Details */

.services-details {
    position: relative;
}

.services-details .image-box img {
    margin-bottom: 40px;
    height:400px;
    margin-top:-40px;
}

.services-details .content {
    position     : relative;
    margin-bottom: 50px;
}

.services-details .content .sec-title h2 {
    font-size     : 60px;
    letter-spacing: -3px;
}

.services-details blockquote {
    position          : relative;
    box-shadow        : 0px 8px 16px 0px rgba(40, 40, 40, 0.14);
    padding           : 30px 40px;
    margin            : 40px 0;
}

.services-details blockquote:before {
    position: absolute;
    content : '';
    left    : 0;
    top     : 0;
    width   : 6px;
    height  : 100%;
}

.services-details blockquote h4 {
    position    : relative;
    font-size   : 22px;
    font-weight : 700;
    padding-left: 45px;
}

.services-details blockquote h4:before {
    position: absolute;
    content : '';
    left    : 0;
    top     : 7px;
    width   : 35px;
    height  : 2px;
}

@media screen and (max-width: 415px) {
    .services-details .image-box img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 105%;

    }

    .heading-8{
        text-align: center;
        font-family: 'Open Sans', sans-serif;
        font-size: 30px;
        color: white;
    }

    .categories-widget-two ul {
        position: relative;
        list-style: none;
        padding: 0px;
        margin-top: 43px;
    }



}
