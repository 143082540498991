.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  padding: 10px 0;
  transition: 0.3s;
  z-index: 100;
}
.site-header.sticky-header {
  background: white;
  padding: 0px 0;
  box-shadow: 0 0 20px rgb(0 0 0/ 10%);
  transition: 0.3s;
}
.header-box {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.site-logo {
  display: inline-block;
}
.site-logo a {
  width: 100%;
}
.img-logo {
  height: 70px;
  max-width: 100%;
  vertical-align: middle;
}
.header-menu {
  display: inline-block;
  text-align: center;
  transition: 0.5s;
}
.header-menu-items {
  font-size: 0;
  line-height: 1;
  list-style: none;
  margin: 0;
  padding: 0;

}

.header-menu-items li {
  font-size: 16px;
  line-height: 26px;
  display: inline-block;
  position: relative;
  margin: 0 30px;
}
.header-menu-items li a {
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  color: #010f2e;
  font-weight: 600;
  font-family: "Jost", sans-serif;
  position: relative;
  transition: 0.3s;
}
.header-menu-items li .active,
.header-menu-items li a:hover {
  color: #811818FF;
}
.sub-items .sub-menu {
  position: absolute;
  top: 100%;
  left: -15px;
  width: 200px;
  height: auto;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 1px 1px 60px rgb(0 0 0 / 10%);
  display: none;
  transition: 0.3s;
  padding-left: 0;
}
.header-menu-items .sub-items .sub-menu li {
  display: block;
  text-align: left;
  margin: 0;
  border-bottom: 1px solid #ebebeb;
  padding: 5px 15px;
}
.show-pages .sub-menu{
  display: block;
  animation-duration: 1s;
  animation-name: slide-up;
}
.show-about .sub-menu {
  display: block;
  animation-duration: 1s;
  animation-name: slide-up;
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(10px);
  }
}

.bookings {
  background-color: #811818FF;
  color: white;
  padding: 10px 5px;
  border-radius: 8px;
  border-color: #811818FF;
  text-decoration: none;
  font-size: 50px;
  width: 100%;
  height: 50px;
}

#mybutton {
  position: fixed;
  bottom: 10px;
  right: 7px;

}
.search-icon a {
  display: inline-block;
  font-size: 20px;
  color: #777777;
  transition: 0.3s;
}

.extra-menu-icon {

  width: 100%;
  height: 60px;
  background-size: 400% 100%;
  background-position: left center;
  align-items: center;
  border-radius: 4px;
  transition: 0.3s;
  text-decoration: none;
  padding: 15px;
  text-align: center;
}

.toggle-btn {
  position: fixed;
  top: 0;
  right: 0;
  width: 70px;
  height: 70px;
  background: #811818FF;
  text-align: center;
  border: none;
  outline: none;
  display: none;
  z-index: 10;
}
.toggle-btn span {
  width: 30px;
  height: 3px;
  background: white;
  display: block;
  margin: 0 auto 6px auto;
  position: relative;
  top: 0;
  opacity: 1;
  transition: 0.3s;
}

@media screen and (max-width: 575px) {
}
@media screen and (max-width: 767px) {
}
@media screen and (max-width: 991px) {
  .nav {
    height: 70px;
    padding: 0;
    box-shadow: 0 0 20px rgb(0 0 0/10%);
  }

  .site-logo {
    position: fixed;
    top: 0;
    left: 30px;
    width: 200px;
    height: 70px;
    display: flex;
    align-items: center;
  }
  .toggle-btn {
    display: block;
  }

  .toggle-nav-menu .toggle-btn {
    display: block;
    background: transparent;
    width: 35px;
    height: 35px;
    top: 1rem;
    right: 1rem;
  }
  .toggle-nav-menu .toggle-btn span {
    background: #811818FF;
  }
  .toggle-nav-menu .toggle-btn span:nth-child(1) {
    transform: rotate(45deg);
    position: relative;
    top: 5px;
  }
  .toggle-nav-menu .toggle-btn span:nth-child(2) {
    display: none;
  }
  .toggle-nav-menu .toggle-btn span:nth-child(3) {
    transform: rotate(-45deg);
    position: relative;
    top: -4px;
  }

  .header-menu-items {
    position: fixed;
    top: 0;
    right: -400px;
    width: 320px;
    height: 100vh;
    overflow: auto;
    background: white;
    text-align: center;
    padding: 100px 0 30px;
    box-shadow: -30px 0 30px rgb(0 0 0 / 10%);
    transition: 0.5s;
  }
  .toggle-nav-menu .header-menu-items {
    transform: translateX(-400px);
  }
  .main-navigation .header-menu-items li {
    display: block;
    margin: -50px 0 10px 0;
    padding: 0 30px 10px;
    opacity: 0;
    transition: 0.5s;
  }
  .toggle-nav-menu .header-menu-items li {
    margin-top: 0;
    opacity: 1;
    transition: 0.5s;
    transition-delay: 0.4s;
  }
  .header-search {
    display: none;
  }
  .sub-items .sub-menu {
    background-color: #FA03233D;
    position: relative;
    width: 100%;
    margin-left: 1rem;
    transition: 0.3s;
  }
  .header-menu-items .sub-items .sub-menu li {
    text-align: center;
  }
}
@media screen and (max-width: 1199px) {
  .header-menu-items li {
    margin: 0 20px;
  }
}


