
::after,
::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

p {
  font-size: 15px;
  line-height: 24px;
  color: #777777;
  font-weight: normal;
  margin-bottom: 12px;
}
a {
  text-decoration: none;
  outline: none;
}

.sec-btn {
  height: 60px;
  display: inline-block;
  font-size: 15px;
  line-height: 30px;
  color: #ffffff;
  background-color: #9b111e;
  font-weight: 500;
  text-transform: uppercase;
  padding: 15px 32px;
  background-size: 400% 100%;
  background-position: left center;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  border: none;
  outline: none;
  transition: 0.3s;
  margin-left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.sec-btn:hover {
  background-position: right center;
  color: #ffffff;
}

@media screen and (min-width: 991px) {
  .sec-btn {
    margin-left: 0;
    transform: translateX(0);
  }
}

.sub-title {
  display: block;
  font-size: 18px;
  line-height: 28px;
  color: #9b111e;
  font-weight: bold;
  font-family: "Jost", sans-serif;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 10px;
}

.h1-title {
  text-align: center;
  font-size: 50px;
  line-height: 60px;
  color: #010f2e;
  font-weight: bold;
  font-family: "Jost", sans-serif;
  margin-bottom: 18px;
  max-width: 640px;
}

.h2-title {
  font-size: 34px;
  line-height: 44px;
  font-weight: bold;
  color: #010f2e;
  font-family: "Jost", sans-serif;
  margin-bottom: 30px;
}
.h3-title {
  font-size: 22px;
  line-height: 32px;
  color: #010f2e;
  font-weight: bold;
  font-family: "Jost", sans-serif;
  margin-bottom: 12px;
}
.h4-title {
  font-size: 35px;
  color: white;
  font-weight: bold;
  font-family: "jost", sans-serif;
  margin-bottom: 20px;
}

.form-box {
  display: block;
  position: relative;
  font-size: 0;
  line-height: 1;
  margin-bottom: 20px;
}
.form-input {
  width: 100%;
  height: 60px;
  background: #ffffff;
  font-size: 15px;
  line-height: 24px;
  color: #777777;
  border: none;
  outline: none;
  padding: 12px 30px;
}
@media screen and (max-width: 767px) {
  .form-input {
    height: 45px;
    padding: 12px 20px;
  }
}

@media screen and (min-width: 390px) {
  .sec-btn {
    font-size: 15px;
  }
}
@media screen and (min-width: 575px) {
  .h2-title {
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 30px;
  }
  .h4-title {
    font-size: 35px;
    line-height: 45px;
    color: #010f2e;

  }
}
@media screen and (min-width: 767px) {
  .sub-title {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .h1-title {
    font-size: 50px;
    line-height: 60px;
  }
  .h2-title {
    font-size: 48px;
    line-height: 58px;
  }
  .h3-title {
    font-size: 25px;
    line-height: 35px;
  }
  .h4-title {
    font-size: 40px;
    line-height: 50px;
  }
}
@media screen and (min-width: 991px) {
  .h1-title {
    font-size: 55px;
    line-height: 65px;
  }
  .h2-title {
    font-size: 52px;
    line-height: 62px;
  }
  .h4-title {
    font-size: 50px;
    line-height: 60px;
  }
}
@media screen and (min-width: 1199px) {
  .h1-title {
    font-size: 57px;
    line-height: 67px;
  }
  .h2-title {
    font-size: 65px;
    line-height: 75px;
  }
}
@media screen and (min-width: 1279px) {
  .h1-title {
    font-size: 68px;
    line-height: 78px;
    color: white;
  }
}
.back-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* pages */
.main {
  width: 100%;
  overflow-x: hidden;
}
.main .site-header {
  background: white;
}
.main-banner {
  position: relative;
}
.main-banner.inner-banner {
  margin-top: 0px;
  padding: 140px 0;
  background-size: 1600px;
  background-position: bottom center;
}
@media screen and (min-width: 767px) {
  .main-banner.inner-banner {
    padding: 140px 0 200px 0;
    background-size: 1920px;
  }
}
@media screen and (min-width: 991px) {
  .main-banner.inner-banner {
    padding: 210px 0 216px 0;
  }
}
.main-banner.inner-banner .banner-content {
  text-align: center;
  padding: 0;
}
.main-banner.inner-banner .banner-content .h1-title {
  color: #010f2e;
  margin: 0 auto 26px auto;
}
.breadcrumb-box {
  text-align: center;
}
.breadcrumb-box ul {
  font-size: 0;
  line-height: 1;
  list-style: none;
  margin-top: 0;
  margin-bottom: 1rem;
}
.breadcrumb-box ul li:first-child {
  padding-left: 0;
  margin-left: 0;
}

.breadcrumb-box ul li {
  font-size: 18px;
  line-height: 28px;
  color: #010f2e;
  display: inline-block;
  padding-left: 24px;
  margin-left: 14px;
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
}
.breadcrumb-box ul li:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 10px;
  height: 10px;
  background: #010f2e;
  border-radius: 50%;
}
.breadcrumb-box ul li:first-child {
  padding-left: 0;
  margin-left: 0;
}
.breadcrumb-box ul li a {
  text-decoration: none;
  font-size: 18px;
  line-height: 28px;
  color: #010f2e;
  text-transform: uppercase;
  font-weight: 500;
  transition: 0.3s;
}

.breadcrumb-box ul li a:hover {
  color: #010f2e;
}


