.main-portfolio {
  position: relative;
  display: grid;
}
@media screen and (max-width: 991px) {
  .main-portfolio {
    padding-top: 73px;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .main-portfolio {
    padding-top: 53px;
  }
}

.portfolio-title .sub-title {
  margin-bottom: 10px;
  margin-left: -40px;
}

.portfolio-title .h2-title{
  margin-left: -40px;

}
.portfolio-tabbing ul {
  margin-right: -7px;
  padding: 2px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.portfolio-tabbing ul li .filter {
  display: inline-block;
  font-weight: 500;
  transition: 0.3s;
  cursor: pointer;
}
.portfolio-tabbing ul li .filter:hover,
.portfolio-tabbing ul li .filter.active {
  color: #811818FF;
  text-decoration: underline;
}

.portfolio {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  padding: 0 15px;
  display: none;
}
.portfolio.md-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.portfolio.md-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
@media screen and (max-width: 991px) {
  .portfolio.md-6,
  .portfolio.md-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media screen and (max-width: 575px) {
  .portfolio.md-6,
  .portfolio.md-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.portfolio-wrapper {
  width: 100%;
  height: 370px;
  position: relative;
  box-shadow: 2px 2px 60px rgb(0 0 0 / 10%);
  border-radius: 4px;
  margin-bottom: 30px;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .portfolio-wrapper {
    height: 300px;
  }
}
@media screen and (max-width: 575px) {
  .portfolio-wrapper {
    height: 250px;
  }
}
.portfolio-wrapper .portfolio-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}
.portfolio-wrapper-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px;
  text-align: center;
  opacity: 0;
  transition: 0.5s;
  z-index: 5;
}
.portfolio-wrapper-text .h3-title {
  color: #ffffff;
  font-weight: 800;
  position: relative;
  top: -10px;
  margin-bottom: 0;
  opacity: 1;
  transition: 0.5s;
}
.portfolio-wrapper-text p {
  color: #ffffff;
  margin-bottom: 23px;
  position: relative;
  top: -10px;
  opacity: 1;
  transition: 0.5s;
}
.portfolio-wrapper::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.5s;
  z-index: 1;
}
.portfolio-wrapper:hover .portfolio-wrapper-text {
  opacity: 1;
  transition-delay: 0.3s;
}
.portfolio-wrapper:hover::before {
  opacity: 0.7;
  transition-delay: 0.3s;
}
.portfolio-wrapper:hover .portfolio-img {
  width: 115%;
  height: 115%;
}
