.main-team {
  position: relative;
  padding: 83px 0 40px 0;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .main-team {
    padding: 26px 0 30px 0;
  }
}
@media screen and (max-width: 991px) {
  .main-team {
    padding: 46px 0 20px 0;
  }
}
@media screen and (max-width: 400px) {
  .main-team {
    padding: 26px 0 30px 0;
  }
  .team-img {
    width: 100%;
    height: 285px;
    max-height: 285px;
    transition: 0.3s;
    background-position: center;
    background-size: cover, 90%;
    background-repeat: no-repeat;
    background-position-y: -30px;
  }


}
.team-title {
  text-align: center;
}
.team-box {
  position: relative;
  overflow: hidden;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 2px 2px 60px rgb(0 0 0 / 8%);
  overflow: hidden;
  transition: 0.3s;
  margin: 5px 15px;
}
@media screen and (max-width: 576px) {
  .team-box {
    transform: translateX(-50%);
  }
}

.team-text {
  position: relative;
  z-index: 1;
}



.team-img {
  width: 100%;
  height: 285px;
  max-height: 285px;
  transition: 0.3s;
  background-position: center;
  background-size: cover, 90%;
  background-repeat: no-repeat;
  background-position-y: -30px;
}

.team-text-box {
  position: relative;
  padding: 24px 30px 21px 30px;
}
.team-text-box p {
  color: #9b111e;
  text-transform: uppercase;
  margin-bottom: 2px;
  transition: 0.3s;
}
.team-text-box .h3-title {
  margin-bottom: 0;
  transition: 0.3s;
}


