.site-footer {
  position: relative;
  background: #1c233d;
  color: white;
  padding: 60px 0;
}
.footer-info {
  background: #fff;
  padding-top: 60px;
  padding-bottom: 10px;
  max-height: 100%;
  text-align: center;
  margin-top: -140px;
  border: 2px solid #1c233d;
  height: 600px;
  width: 270px;
  max-width: 100%;
  min-width: 100%;


}
.footer-logo img {
  height: 120px;
  position: relative;
  margin-top: -10px;
  margin-bottom: 25px;
}
.footer-info p {
  text-align: center;
  font-family: "Jost", sans-serif;
  flex-wrap: wrap;
  vertical-align: center;
  margin: -40px 0px 30px;
  color: #575555;
}


.social-icons a {
  width: 41px;
  height: 41px;
  background: black;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #ffffff;
  border-radius: 50%;
  margin-right: 14px;
  position: relative;
  transition: 0.3s;
}

.social-icons a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #ab0016, #CB3030CE);
  border-radius: 50%;
  opacity: 0;
  transition: 0.3s;
}
.social-icons a:hover::before {
  opacity: 1;
}
.social-icon {
  z-index: 1;
  color: white;
}
.h3-title.footer-title {
  color: white;
  position: relative;
  padding-bottom: 2px;
  margin-bottom: 26px;
}
.h3-title.footer-title::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 70px;
  height: 1px;
  background: linear-gradient(to right, #ab0016, #CB3030CE);
}
.footer-contact {
  padding-bottom: 1.5rem;
  margin-left: 30px;
}
.footer-contact-box {
  min-height: 40px;
  margin-bottom: 12px;
}
.footer-contact-link {
  display: inline-block;
  padding-left: 51px;
  position: relative;
}
.footer-contact-link .icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 40px;
  height: 40px;
  background: linear-gradient(to right, #ab0016, #CB3030CE);
  border-radius: 50%;
  color: #ffffff;
  font-size: 20px;
}
.footer-contact-link a {
  display: block;
  color: #a9aebe;
  font-size: 15px;
  line-height: 25px;
  transition: 0.3s;
  text-decoration: none;
}

.footer-contact-link a:hover {
  color: #ab0016;
}
.our-links {
  padding-left: 0px;
  padding-bottom: 1.5rem;
}
.our-links ul,
.our-services ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0;
  line-height: 1;
  column-count: 2;

}
.our-services ul {
  column-count: 1;
}

.our-links ul li {
  display: block;
  color: #a9aebe;
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 6px;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
  text-decoration-color: black;

}
.our-links ul li a{
  text-decoration-color: black;
  text-decoration: none;
  color: #a9aebe;
}



.site-footer li:hover {
  color: #ab0016;
}

.footer-last {
  background: #10152a;
  padding: 30px 0;
}
.copy-right {
  text-align: center;
  margin-bottom: 10px;
}
.copy-right p {
  color: white;
  margin-bottom: 0;
}
.footer-last-link {
  text-align: center;
  padding-right: 1rem;
}
.footer-last-link ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0;
  line-height: 1;
}
.footer-last-link ul li {
  display: inline-block;
  font-size: 15px;
  line-height: 25px;
  padding-left: 20px;
  position: relative;
  margin-left: 1rem;
}
.footer-last-link a {
  text-decoration: none;
  color: white;
}
.footer-last-link ul li::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  width: 8px;
  height: 8px;
  background: #ab0016;
  border-radius: 50%;
}

@media screen and (min-width: 575px) {
}
@media screen and (min-width: 767px) {
  .site-footer {
    padding: 80px 0;
  }
}
@media screen and (min-width: 991px) {
  .site-footer {
    padding: 120px 0 100px 0;
  }
  .footer-info {
    padding-bottom: 0px;
  }
  .footer-contact {
    padding-bottom: 0px;
  }
  .our-links {
    padding-left: 30px;
    padding-bottom: 0px;
  }
  .footer-last {
    padding: 22px 0;
  }
  .copy-right {
    text-align: left;
    margin-bottom: 0;
  }
  .footer-last-link {
    text-align: right;
    padding-right: 0px;
  }
  .footer-last-link ul li {
    margin-left: 33px;
  }
}
@media screen and (min-width: 1199px) {
  .our-links {
    padding-left: 60px;
  }
}
