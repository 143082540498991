.main-banner {
  background-color: #f8f9fa;
  margin-top: -90px;
  padding: 180px 0 0;
}


#home img{
  min-height: 639px;
  max-height: 639px;
}

.sec-btn-home {
  height: 60px;
  display: grid;
  font-size: 15px;
  line-height: 30px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 15px 32px;
  background-size: 400% 100%;
  background-position: left center;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  border: none;
  outline: none;
  transition: 0.3s;
  text-align: center;
  margin-top: -14px;
  background-color: #9b111e;
  text-decoration: none;
  color: whitesmoke;
  max-width: initial;
  margin-left: auto;
  margin-right: auto;

}


.carousel-item{
  width: 600px;
  max-width: 100%;
}
.banner-content {
  padding: 50px 0 15px;
}
.wel-span {
  color: black;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
  font-family: "Jost", sans-serif;
  text-transform: uppercase;
  position: relative;
  text-align: center;
}
.wel-h1 {
  text-align: center;
  font-size: 60px;
  line-height: 60px;
  color: #010f2e;
  font-weight: bold;
  font-family: "Jost", sans-serif;
  margin-bottom: 18px;
  max-width: 640px;
}
.wel-p1 {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 31px;
  color: #777777;
  font-weight: normal;
  text-align: center;
}

.banner-img {
  width: 100%;
  height: 100%;
  position: relative;
}

@media screen and (min-width: 767px) {
  .banner-content {
    padding: 90px 0 35px;
  }
  .wel-h1 {
    font-size: 50px;
    line-height: 60px;
  }
}
@media screen and (min-width: 991px) {
  .banner-content {
    padding: 0;
  }
  .wel-h1 {
    font-size: 55px;
    line-height: 65px;

  }
  .wel-span {
    text-align: left;
    font-size: 22px;
    margin: 2rem 0;
  }


}
@media screen and (min-width: 1199px) {
  .wel-h1 {
    font-size: 57px;
    line-height: 67px;
  }
}
@media screen and (min-width: 1279px) {
  .wel-h1 {
    font-size: 68px;
    line-height: 78px;
  }
}

@media screen and (max-width: 415px){
   #home img {
     min-height: 400px;
     max-height: 400px;

   }

}
