.portfolio-details {
    position: relative;
    padding : 120px 0 90px;
}

.image-box-blog img {
    display: block;
    max-width: 100%;
    width: 400px;
    height: auto;
    vertical-align: middle;
    border-style: none;
    outline: none;
    margin-left: auto;
   margin-right: auto;

}

.portfolio-details .text {
    margin-bottom: 30px;
}
.portfolio-details .text a {
    margin-bottom: 30px;
    text-decoration: none;
}


.portfolio-details .sec-title{
    text-align: center;
}

/* Project Post Pagination */

.project-post-pagination {
    position      : relative;
    margin-bottom : 40px;
    border-bottom : 1px solid #ebebeb;
    padding-bottom: 40px;
}

.project-post-pagination .wrapper-box {
    position         : relative;
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    -webkit-box-pack : justify;
    -ms-flex-pack    : justify;
    justify-content  : space-between;
    -ms-flex-wrap    : wrap;
    flex-wrap        : wrap;
    margin: auto;
}

.project-post-pagination .next-post,
.project-post-pagination .prev-post {
    position: relative;
    border  : 2px solid #ebebeb;
    padding : 19px 30px 20px;
    cursor  : pointer;

}

.project-post-pagination h5 {
    font-size  : 14px;
    font-weight: 400;
    line-height: 1em;
    display    : inline-block;
    color      : #282828;
}
.project-post-pagination h5 a {
    text-decoration: black;
    color: black;
}

.project-post-pagination h4 {
    font-size  : 24px;
    font-weight: 700;
}

.project-post-pagination .next-post {
    text-align: right;
}

.project-post-pagination .page-view {
    width      : 100px;
    height     : 100px;
    font-size  : 30px;
    line-height: 100px;
    text-align : center;
    color      : #fff;
    cursor     : pointer;
}

/** FAMILY PLANNING BLOG CSS **/
table{

    border: 2px solid #8f8f8f;
    letter-spacing: 1px;
    margin-bottom: 20px;
    font-family: "Roboto Condensed",Helvetica,Arial,sans-serif;
    font-size: 0.875rem;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
}




td{
    vertical-align: bottom;
    border: 1px solid rgb(190, 190, 190);
    padding: 8px;
    line-height: 1.42857;
    text-align: left;
    position: static;
    display: flex;
    float: none;
}


th{
    position: static;
    display: table-cell;
    float: none;
    border: 1px solid rgb(190, 190, 190);
    padding: 5px 10px;

}

.img-responsive {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-width: 50%;
    height: auto;
    vertical-align: middle;
    border: 0;

}





